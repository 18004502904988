// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-blog-tsx": () => import("./../../../src/pages/new-blog.tsx" /* webpackChunkName: "component---src-pages-new-blog-tsx" */),
  "component---src-pages-wilhelm-tsx": () => import("./../../../src/pages/wilhelm.tsx" /* webpackChunkName: "component---src-pages-wilhelm-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */)
}

